export const EXTERNAL_LINKS = {
  FAQ: "https://help.enkor.kr/articles/housing/faq",
  HOST_HELP_CENTER: "https://help.enkor.kr/host/event",
  HOST_LANDING: "https://slashpage.com/enkorhostlanding",
  FIP: "https://www.letsfip.com?utm_source=enkorstay&utm_medium=main&utm_campaign=shortcut",
  ENKOR_ABOUT_US: "https://enkor.career.greetinghr.com",
  ENKOR_CAREERS: "https://enkor.career.greetinghr.com/guide",
  ENKOR_CULTURE: "https://enkor.career.greetinghr.com/culture",
  HOST_FORM_LINK: "https://forms.gle/YbrkbYVneCqFfmUa7",
} as const;

export const ARC_KAKAOTALK_CHANNEL_LINK = "http://pf.kakao.com/_zMZrG" as const;
export const STAY_CHANNEL_IO_LINK = "https://stay-enkor.channel.io/" as const;

export const BEDDING_LINKS = {
  FAQ: "https://help.enkor.kr/articles/bedding/extension",
} as const;

export const SOCIAL_LINKS = {
  ENKOR_STAY: "https://stay.enkor.kr/",
  ENKOR_BLOG: "https://stay.enkor.kr/blog",
  INSTAGRAM_STAY: "https://www.instagram.com/enkorstay_seoul/",
  FIP: "https://www.letsfip.com",
  INSTAGRAM_FIP: "https://www.instagram.com/letsfip/",
  TIKTOK: "https://www.tiktok.com/@letsfip_",
} as const;

export const LEGAL_DOCUMENT_LINKS = {
  TERMS_OF_CONDITIONS: {
    en: "https://help.enkor.kr/policies/terms_of_service_en",
    ko: "https://help.enkor.kr/policies/terms_of_service_kr",
  },
  CANCELLATION_AND_REFUND_POLICY: {
    en: "https://help.enkor.kr/policies/guest_en",
    ko: "https://help.enkor.kr/policies/guest_kr",
  },
  PRIVACY_POLICY: {
    en: "https://help.enkor.kr/policies/privacy_policy_en",
    ko: "https://help.enkor.kr/policies/privacy_policy_kr",
  },
  HOST_TERMS_OF_CONDITIONS: {
    en: "https://help.enkor.kr/policies/host_en",
    ko: "https://help.enkor.kr/policies/host_kr",
  },
} as const;

export const ARC_LEGAL_DOCUMENT_LINKS = {
  FAQ: {
    en: "https://help.enkor.kr/articles/category/english/",
    ko: "https://help.enkor.kr/articles/category/korean/",
  },
  POLICY: {
    en: "https://help.enkor.kr/policies/arc/privacy_policy_en",
    ko: "https://help.enkor.kr/policies/arc/privacy_policy_kr",
  },
  TERMS_OF_SERVICE: {
    en: "https://help.enkor.kr/policies/arc/terms_of_service_en",
    ko: "https://help.enkor.kr/policies/arc/terms_of_service_kr",
  },
  REFUND: {
    en: "https://help.enkor.kr/policies/arc/refund_policy",
    ko: "https://help.enkor.kr/policies/arc/refund_policy",
  },
  HELP_CENTER: {
    en: "https://help.enkor.kr/articles/visa/en/quickstart",
    ko: "https://help.enkor.kr/articles/visa/ko/quickstart",
  },
} as const;

export const LETSFIP_LEGAL_DOCUMENT_LINKS = {
  TERMS_OF_CONDITIONS: {
    en: "https://help.enkor.kr/policies/letsfip/terms_of_service_en",
    ko: "https://help.enkor.kr/policies/letsfip/terms_of_service_kr",
  },

  PRIVACY_POLICY: {
    en: "https://help.enkor.kr/policies/letsfip/privacy_policy_en",
    ko: "https://help.enkor.kr/policies/letsfip/privacy_policy_kr",
  },
} as const;

export const LETSFIP_APP_STORE_LINK = {
  ANDROID:
    "https://play.google.com/store/apps/details?id=com.letsfip.pilot&referrer=utm_source%3Dletsfip%26utm_medium%3Dappdownload%26utm_campaign%3Dlaunch%26anid%3Dadmob",
  IOS: "itms-apps://itunes.apple.com/app/id6497171060?pt=letsfip&ct=appdownload&mt=8",
} as const;
